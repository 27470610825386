import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

export default function Footer(props) {

    const { t } = useTranslation();

    const { location } = props;

    const role = useSelector(state => 
        state.authReducer?.user?.role && state.authReducer.user.role !== "" 
            ? state.authReducer.user.role 
            : "guest"
    );


    if (location.pathname.match(/login/) || location.pathname.match(/register/)) {
        return null;
    }

    if (location.pathname.match(/admin/)) {
        if (role == "admin") {
            return null;
        }
    }

    return (
        <footer>
            {/* social */}
            <div className="follow-section text-center">
                <span>{t("follow")} :</span>
                <div className="social-links">
                    <a href="/"><i className="fab fa-facebook-f"></i></a>
                    <a href="/"><i className="fab fa-twitter"></i></a>
                    <a href="/"><i className="fab fa-linkedin-in"></i></a>
                    <a href="/"><i className="fab fa-youtube"></i></a>
                    <a href="/"><i className="fab fa-instagram"></i></a>
                    <a href="/"><i className="fab fa-flickr"></i></a>
                </div>
            </div>

            <div className="footer-section">
                <div className="container-fluid px-md-5">
                    <div className="row f-row">
                    <div className="col-2" style={{ marginLeft: "150px",marginTop:"25px" }}><img className="mw-100" src="/img/home/compass.jpg" alt="compass" /></div>
                        <div className="col-2" style={{color: "blue", marginLeft: "150px" }}><img className="mw-100" src="/img/home/b-logo.svg" alt="logo" style={{width:'300px'}}/></div>
                        <div className="col-2" style={{ marginLeft: "150px" }}><img className="mw-100" src="/img/home/mfa.jpg" alt="mfa" /></div>
                        
                    </div>
                    {/* <div className="footer-links d-flex flex-row-reverse m-0 w-100 row">
                        <div className="col-lg-10 links-section order-2 order-lg-0">
                            <a href="/">{t("footer.a_8")}</a>
                            <a href="/news">{t("footer.a_6")}</a>                          
                            <a href="/resources">{t("footer.a_10")}</a>                         
                        </div>
                        <div className="col-lg-2 QL">
                            <span className="mb-0">{t("footer.a_9")}</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}

