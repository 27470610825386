import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ServiceCategoryList = ({ onCategorySelect }) => {
  // State variables
  const [serviceCategories, setServiceCategories] = useState([]);
  const [Lang, setLang] = useState('en');
     const { t } = useTranslation(); // Example: you can replace 'en' with dynamic language if needed

  // Predefined list of IDs (you can customize this list based on your API)
  const categoryIds = [1, 2, 3, 4, 5, 7, 8,9,10]; 
  const [activeCategory, setActiveCategory] = useState(1);// Example category IDs to filter by

  // Function to fetch data from API based on serviceCategoryId
  const fetchServiceCategories = (id = '') => {
    const url = `/service-provider?serviceCategoryId=${id}`;
    axios
      .get(url, {
        headers: { 'locale': Lang },
      })
      .then((response) => {
        setServiceCategories(response.data.data.collection); // Set the filtered service categories
      })
      .catch((error) => {
        console.error('Error fetching service categories:', error);
      });
  };
 
  // Use effect to fetch all service categories on initial load
  useEffect(() => {
    fetchServiceCategories(1); // Fetch all categories initially (without filtering)
  }, [Lang]); // Re-fetch when language changes

  // Handle button click for filtering by ID
  const handleButtonClick = (id) => {
    if (onCategorySelect) {
      onCategorySelect(id); // Call the parent callback function to update the selected category ID
    }
    setActiveCategory(1);
    fetchServiceCategories();
     // Fetch filtered data based on selected ID
  };
  // Function to render category name based on ID
  const renderCategoryName = (id) => {
    switch (id) {
     case 8:
        return t("CP");
      case 5:
        return t("GBV");
      case 9:
        return t("ca_ss");
      case 2:
        return t("Health");
      case 10:
        return t("MHPSS");
      case 7:
        return t("Housing");
      case 3:
        return t("Education");
      case 1:
        return t("Livel");
      case 4:
        return t("Legal");
      // default:
      //   return id=1;
    }
  };

  return (
    <div>
      {/* <h1>Service Categories</h1> */}

      {/* Filter buttons based on predefined category IDs */}
      <div>
        {categoryIds.map((id) => {
          const isActive = activeCategory === id; 
          return (
            <button className="w3-button  mx-2 py-2 border border-primary bg-light rounded-pill active"
              key={id}
              onClick={() => handleButtonClick(id)}
              style={{ margin: "5px",
                padding: "15px",
               // marginBottom:"7%",
                marginLeft :"7%",
                marginRight :"7%",
                // backgroundColor: {isActive ? "#007bff" : "#e0e0e0"}, // Active button color (blue), inactive (gray)
                // color: {isActive ? "white" : "black"}, // Active button text color (white), inactive (black)
                border: "none",
                borderRadius: "5px" }}
            >
              {/* {id === 1 ? "CP" : id === 2 ? "GBV" :id=== 3 ? "Cash Assistance":id=== 4 ? "Health": id === 5 ?" MHPSS": id ===6 ?" Housing" : id=== 7 ?" Education" : id=== 8 ? "Livelihoods": id ===9 ?"Legal Assistance": id=== 10?"Organizational Map": id===1} */}
              {renderCategoryName(id)}

            </button>
  
          );
        })}
        
      </div>
    </div>
  );
};

export default ServiceCategoryList;


