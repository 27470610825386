import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Pager from '../../../../components/Pager';
import AdminCard from '../../../../components/Cards/NewsCard';
import axiosInstance from '../../../services/axiosInstance';
import ServiceCategoryList from '../../../../components/Tags';
import { NavLink } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

function News({isAdmin}) {

    const { t } = useTranslation();
   const [isLoading, setIsLoading] = React.useState(true);
   const [newsList, setNewsList] = useState([]);
   const [selectedCategoryId, setSelectedCategoryId] = useState(1); 
   const [total, setTotal] = React.useState(0);
   const [serviceProviderList, setServiceProviderList] = React.useState([]);
   const [page, setPage] = React.useState(1);
   const [perPage, setPerPage] = React.useState(10);
   const [fitlerValues, setFitlerValues] = React.useState([]);
   const Lang = localStorage.getItem("i18nextLng");
   const outerToast = React.useRef(null);
    useEffect(() => {
        loadServiceProviderList(); // Trigger fetching based on initial selected category
     }, [Lang, selectedCategoryId]); 


    function loadServiceProviderList(params = {}) {
        params.perPage = params.perPage || perPage;
        params.page = params.page || page;
        params = { ...params, ...fitlerValues };
      
        setIsLoading(true);
        axiosInstance()
          .get(`/service-provider?serviceCategoryId=${selectedCategoryId}`, { params, headers: { locale: Lang } })
          .then((response) => populateServiceProviderList(response))
          .catch((error) => {
            setIsLoading(false);
            console.error('Error loading service providers:', error);
          });
      }
      
     function populateServiceProviderList(response) {
             setIsLoading(false)
            setServiceProviderList(response.data.data.collection)
             setTotal(response.data.data.pageInfo.total);
     }
         const handleCategorySelect = (id) => {
            setSelectedCategoryId(id); // Update selected category ID
          };    
  
    const onBasicPageChange = (event) => {
        axiosInstance().get(`service-provider?serviceCategoryId=${selectedCategoryId}&page=${event.page + 1}&perPage=${event.rows}`)
            .then((res) => {
                const data = res.data.data.collection;
                data?.length
                    &&
                    populateServiceProviderList(res);
                setTotal(res.data.data.pageInfo.total);
                console.error('Error loading service providers:', total);
            })
    }
    return (
        <div className="dashboard-section withPager position-relative pb-0 h-100 newsp">
            <Toast position='top-center' ref={outerToast} />
            <div className="px-4 ">
                <div className="content-wrapper pt-4">

                    <div className="labels d-flex justify-content-between w-100 align-items-center mb-5">
                        <span className="reset-bold text-capitalize main-title-res">{t("SP")}</span>
                        {isAdmin && <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to="/admin/news/add">{t("addN")}</NavLink></button>}
                    </div>
                    <div className="news-page tags">
                    <ServiceCategoryList onCategorySelect={handleCategorySelect} />
                        <div className="cards-wrapper row" >
                            {                               
                                serviceProviderList?.length
                                    ?
                                    serviceProviderList?.map((item) => (
                                        <div key={item.id} className="col-lg-3 col-md-4 col-sm-6 card-col mb-4">
                                            <AdminCard
                                              type={isAdmin ? "admin/service-provider/" : "news"}
                                             serviceProvider={item}  isAdmin={isAdmin}/>
                                        </div>
                                    ))
                                    :
                                    <p className='no-da mx-3'>{t("noContent")}</p>
                            }
                        </div>

                    </div>

                </div>
            </div>
            <Pager total={total} onPageChange={onBasicPageChange} />
        </div >
    )
}
export default News

   