import React from 'react';
import { useParams } from 'react-router';
import Map from "../../components/Map";
import axiosInstance from "../services/axiosInstance";
import EventCard from '../../components/Cards/EventCard';
import { NavLink } from 'react-router-dom';

import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

import ServiceCard from '../../components/Cards/ServiceCard';

function ServiceProviderPage({ isAdmin }) {

    const { id } = useParams();
    const toast = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const { t } = useTranslation();

    // const [eventList, setEventList] = React.useState([]);
    const [serviceProvider, setServiceProvider] = React.useState({
        nickname: '',
        description: '',
        logo: '',
        services: [],
        phone: '',
        email: '',
        address: '',
        website:'',
        facebook:'',
        working_hours:'',
        focal_points:[0],
        
    });


    const Lang = localStorage.getItem("i18nextLng");

    React.useEffect(() => {
        setIsLoading(true);
    
        axiosInstance()
            .get(`/service-provider/${id}`, { headers: { locale: Lang } })
            .then((response) => {
                setServiceProvider(response.data.data); // Assuming the API returns this structure
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('API error:', error);
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to load service provider data' });
            });
    }, [id, Lang]);


    return (
        <>
            <div className="position-relative">
                <Toast position="top-center" ref={toast} />

                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>
                {isAdmin && <button className="submit-button m-0 custom"><NavLink className="white" to={`/admin/service-providers/${id}/edit`}>{t("edit")}</NavLink></button>}
                    <div></div>
                    <div className="row">
                        <div className="col-md-3">
                            {/* service image */}
                            <img alt="service-image" className="img-fluid serv_img w-100" src={serviceProvider.logo || "/img/example.png"} />

                        </div>
                        {/* service details */}
                        <div className="col-md-9">

                            <p className="section-title mt-4 mt-md-0">{serviceProvider.nickname}</p>
                            {/* service categories */}

                            <div className="noMarginParent">
                                {serviceProvider && serviceProvider.services &&
                                    serviceProvider.services.map((serviceCategory, i) =>
                                        <span className="text-primary  service-category-small " key={i}>{serviceCategory.name}</span>
                                    )
                                }
                            </div>
 
                            {/* <p className="mt-5 description">{serviceProvider.description}</p> */}
                           <div className="contact ">
                                <label className="font-weight-bold label mt-4 mb-2">{t("contact information")}</label>
                                   <div className="phone">
                                      <h5>phone:</h5>
                                      {serviceProvider.phone && <p className="text-primary"><i className="pi pi-phone"></i> {serviceProvider.phone}</p>}
                                      <h5>e-mail:</h5>
                                      {serviceProvider.email && <p className="text-primary"><i className="pi pi-envelope"></i> {serviceProvider.email}</p>}
                                      <h5>office address:</h5>
                                      {serviceProvider.address && <p className="text-primary"><i className="pi pi-envelope"></i> {serviceProvider.address}</p>}
                                      <h5>website:</h5>
                                      {serviceProvider.website && <p className="text-primary"><i className="pi pi-globe"></i> {serviceProvider.website}</p>}
                                      <h5>social media link:</h5>
                                      {serviceProvider.facebook && <p className="text-primary"><i className="pi pi-globe"></i> {serviceProvider.facebook}</p>}
                                   </div>
                                   <div className="focal_points">
                                       <label className=" label mt-4 mb-2">{t("focal_points")}</label>
                                        <div className="focal">
                                            <h5>name:</h5>
                                            <p className=" ">{serviceProvider.focal_points[0].name}</p>
                                            <h5>e.mail:</h5>
                                            <p className=" ">{serviceProvider.focal_points[0].email}</p>
                                            <h5>phone:</h5>
                                            <p className="">{serviceProvider.focal_points[0].phone}</p>
                                            <h5>position:</h5>
                                            <p className="">{serviceProvider.focal_points[0].position}</p>

                                        </div>
                                   </div>
                            </div>
                            <div className="details">
                            <div className="working">
                               <label className=" label mt-4">{t("working hours")}</label>
                               <p className=" description">{serviceProvider.working_hours}</p>
                            </div>
                            <div className="working">
                               <label className=" label mt-4">{t("information")}</label>
                               <p className=" description">{serviceProvider.description}</p>
                            </div>
                            <div className="working">
                               <label className=" label mt-4">{t("target segment")}</label>
                               <p className=" description">{serviceProvider.description}</p>
                            </div>
                            <div className="working">
                               <label className=" label mt-4">{t("services provider")}</label>
                               <p className=" description">{serviceProvider.description}</p>
                            </div>
                            </div>
                           

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ServiceProviderPage