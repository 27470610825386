import React from 'react';
import Icons from '../../../assets/Icons/pnge.png';
import { useTranslation } from 'react-i18next';

const ServiceProviderCard = ({ serviceProvider ,handleEdit,handleRemove ,role ,isAdmin}) => {
    const { t } = useTranslation('general');

  return (
  <>
    {isAdmin ? (
      <a
        href={`/admin/service-provider/${serviceProvider.id}`}
        className="d-block h-100"
      >
        <div className="card h-100 service-card wow bounceInUp" data-wow-delay="0.1s">
          <div className="bg-light rounded service-item">
            <div className="service-content d-flex align-items-center justify-content-center p-4">
              <div className="service-content-icon text-center">
                <div className="service-img">
                  <img
                    alt="card-image"
                    className="cover card-img-top card-animate"
                    src={serviceProvider.logo || "/img/example.png"}
                  />
                </div>
                <div className="detaliscard">
                  <h4 className="mb-3 card-title flex">{serviceProvider.name}</h4>
                  <p className="mb-4 card-text">{serviceProvider.email}</p>
                  <a
                    href="#"
                    className="btn btn-primary px-4 py-2 rounded-pill"
                  >
                    Read More
                  </a>
                  <div className='news-options text-center'>
                        <button onClick={handleEdit} className='remove-button reset-button text-success px-0'>
                            <i className='fas fa-edit mx-2 d-inline-block' />
                            <span>{t('edit')}</span>
                        </button>
                        <button onClick={handleRemove} className='remove-button reset-button text-danger px-0'>
                            <i className='fas fa-trash mx-2 d-inline-block' />
                            <span>{t('remove')}</span>
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    ) : (
      <a
        href={`/service-provider/${serviceProvider.id}`}
        className="d-block h-100"
      >
        <div className="card h-100 service-card wow bounceInUp" data-wow-delay="0.1s">
          <div className="bg-light rounded service-item">
            <div className="service-content d-flex align-items-center justify-content-center p-4">
              <div className="service-content-icon text-center">
                <div className="service-img">
                  <img
                    alt="card-image"
                    className="cover card-img-top card-animate"
                    src={serviceProvider.logo || "/img/example.png"}
                  />
                </div>
                <div className="detaliscard">
                  <h4 className="mb-3 card-title flex">{serviceProvider.name}</h4>
                  <p className="mb-4 card-text">{serviceProvider.email}</p>
                  <a
                    href="#"
                    className="btn btn-primary px-4 py-2 rounded-pill"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    )}
  </>
);
    
}

export default ServiceProviderCard
