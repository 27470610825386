import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ServiceProviderCard = ({ serviceProvider, isAdmin }) => {
  const { t } = useTranslation();

  // Reusable card content to avoid duplication
  const renderCardContent = () => (
    <div className="card h-100 service-card wow bounceInUp" data-wow-delay="0.1s">
      <div className="bg-light rounded service-item">
        <div className="service-content d-flex align-items-center justify-content-center p-4">
          <div className="service-content-icon text-center">
            <div className="service-img">
              <img
                alt="card-image"
                className="cover card-img-top card-animate"
                src={serviceProvider.logo || "/img/example.png"}
              />
            </div>
            <div className="detaliscard">
              <h4 className="mb-3 card-title flex">{serviceProvider.name}</h4>
              {/* <p className="mb-4 card-text">{serviceProvider.email}</p> */}
              <button className="btn btn-primary px-4 py-2 rounded-pill">
                {t('readMore')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <a
      href={
        isAdmin
          ? `/admin/news/${serviceProvider.id}`
          : `/news/${serviceProvider.id}`
      }
      className="d-block h-100"
    >
      {renderCardContent()}
    </a>
  );
};

ServiceProviderCard.propTypes = {
  serviceProvider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    logo: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  handleEdit: PropTypes.func,
  handleRemove: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default ServiceProviderCard;
