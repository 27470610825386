import React from 'react'

function AdminCard({ data, type }) {
    return (
        <a href={`/${type}/${data.id}`} className="d-block h-100">
            

            <div className="card news-card h-100">
                <div className="img-cont">
                    <img alt="card-image" className="cover card-img-top card-animate" src={data.featured_image || "/img/example.png"} />
                </div>
                {/* <div className="card-body d-flex justify-content-between">
                    <span className="d-inline-block"><i className="fas fa-calendar-alt mx-1"></i></span>
                </div> */}
                <div className="card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">{data.description}</p>
                </div>


            </div>

        </a>
    )
}

export default AdminCard
