import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axiosInstance from "../../../services/axiosInstance";
import { useTranslation } from 'react-i18next';
import MediaView from '../../../../components/MediaView';
import VideoPlaceholder from '../../../../components/VideoPlaceholder';
import { NavLink } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function News_details({ isAdmin }) {
    const { id } = useParams();
    const toast = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const { t } = useTranslation();
    const [newsList, setNewsList] = useState([]);
    const history = useHistory();

    // const [eventList, setEventList] = React.useState([]);
    const [serviceProvider, setServiceProvider] = React.useState({
        nickname: '',
        name: '',
        description: '',
        asylum_types: '',
        service_provided: '',
        logo: '',
        location: "",
        services: [],
        phone: '',
        email: '',
        address: '',
        website: '',
        facebook: '',
        working_hours: '',
        focal_points: [],

    });
    function makeLinksClickable(text) {
        // Regular expression to detect URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Split the text and replace URLs with anchor tags
        return text.split(urlRegex).map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                        {part}
                    </a>
                );
            }
            return part;
        });
    }
    function handleRemove(id) {
        setIsLoading(true);
        axiosInstance().post(`service-providers/${id}/delete`)
            .then((res) => {
                // If the service provider is successfully deleted,
                // remove it from the service providers list
                const newList = newsList.filter(link => link.id !== id);
                setNewsList(newList);
                setIsLoading(false);

                // Show success message
                alert('Service provider deleted successfully!');

                // Redirect to admin/news after deletion
                history.push('/admin/news');
            })
            .catch((error) => {
                setIsLoading(false);
                // Show error message
                alert('Failed to delete the service provider. Please try again.');
                console.error('Delete error:', error);
            });
    }


    const Lang = localStorage.getItem("i18nextLng");

    React.useEffect(() => {
        setIsLoading(true);

        axiosInstance()
            .get(`/service-provider/${id}`, { headers: { locale: Lang } })
            .then((response) => {
                setServiceProvider(response.data.data); // Assuming the API returns this structure
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('API error:', error);
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to load service provider data' });
            });
    }, [id, Lang]);

    return (
        <>
            <div className="position-relative">
                <Toast position="top-center" ref={toast} />

                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>
                    <div className="labels d-flex justify-content-end w-100 align-items-center mb-5">
                        {isAdmin && (
                            <>
                                <button className="submit-button my-auto mx-3 custom text-capitalize d-inline-block">
                                    <NavLink className="white" to={`/admin/news/${id}/edit`}>
                                        {t("edit")}
                                    </NavLink>
                                </button>
                                <button className="submit-button m-0 custom" onClick={() => handleRemove(id)}>
                                    <NavLink className="white" to={`/admin/news/${id}`}>
                                        {t("remove")}
                                    </NavLink>
                                </button>
                            </>
                        )}
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-md-3">
                                {/* service image */}
                                <img alt="service-image" className="img-fluid serv_img w-100" src={serviceProvider.logo || "/img/example.png"} />
                                <p className="sub-title mt-4 mt-md-0">{serviceProvider.nickname}</p>
                                {/* service categories */}

                                <div className="noMarginParent">
                                    {serviceProvider && serviceProvider.services &&
                                        serviceProvider.services.map((serviceCategory, i) =>
                                            <button
                                                className="btn btn-primary px-4 py-2 rounded-pill"
                                                onClick={() => window.location.href = `/news`}
                                                key={i}>{serviceCategory.name}</button>
                                        )
                                    }
                                </div>


                            </div>
                            {/* service details */}
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="details">
                                        <div className="contact ">
                                            <label className="font-weight-bold label mt-4 mb-2">{t("auth.register.step_1.password")}</label>
                                            <div className="phone">
                                                <h5>{t("auth.register.step_1.phone")}</h5>
                                                {serviceProvider.phone && <p className="text"><i className="pi pi-phone"></i> {serviceProvider.phone}</p>}
                                                <h5>{t("auth.register.step_1.emailAddress")}</h5>
                                                {serviceProvider.email && <p className="text"><i className="pi pi-envelope"></i> {serviceProvider.email}</p>}
                                                <h5>{t("auth.register.step_1.emailAddressOp")}</h5>
                                                {serviceProvider.address && <p className="text"> {serviceProvider.address}</p>}
                                            </div>
                                            {Array.isArray(serviceProvider.focal_points) && serviceProvider.focal_points.length > 0 ? (
                                                <div className="focal">
                                                    <label className=" label mt-4 mb-2">{t("auth.register.step_4.title")}</label>
                                                    {serviceProvider.focal_points.map((focalPoint, index) => (
                                                        <div key={index} className="focal_point">
                                                            {/* <label className=" label mt-4 mb-2">{t("auth.register.step_4.title")}</label> */}
                                                            <div className="mb-md-0 mb-3 fff">
                                                                {/* <label>{t("num")}</label> */}
                                                                <p>{index + 1}</p>
                                                            </div>
                                                            <div className="mb-md-0 mb-3 ff">
                                                                <label>{t("auth.register.step_1.fullName")}</label>
                                                                <p>{focalPoint.name}</p>
                                                            </div>
                                                            <div className="mb-md-0 mb-3 ff">
                                                                <label>{t("auth.register.step_1.emailAddress")}</label>
                                                                <p>{focalPoint.email}</p>
                                                            </div>
                                                            <div className=" mb-md-0 mb-3 ff">
                                                                <label>{t("auth.register.step_1.phone")}</label>
                                                                <p>{focalPoint.phone}</p>
                                                            </div>
                                                            <div className=" mb-md-0 mb-3 ff">
                                                                <label>{t("auth.register.step_4.position")}</label>
                                                                <p>{focalPoint.position}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.website")}</label>
                                            {serviceProvider.website && <a className="text-primary" href={serviceProvider.website}><i className="pi pi-globe"></i> {serviceProvider.website}</a>}
                                        </div>

                                        <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.shortName_place")}</label>
                                            {serviceProvider.location && <p className="text"> {makeLinksClickable(serviceProvider.location)}</p>}
                                        </div>

                                        {!serviceProvider.description ? <></> : <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.facebook")}</label>
                                            {serviceProvider.facebook && <a className="text-primary"><i className="pi pi-globe"></i> {makeLinksClickable(serviceProvider.facebook)}</a>}
                                        </div>
                                        }
                                        {!serviceProvider.working_hours ? <></> : <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_3.col_1.workingHours")}</label>
                                            <p className=" text">{serviceProvider.working_hours}</p>
                                        </div>
                                        }
                                        {!serviceProvider.description ? <></> : <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.linkedin")}</label>
                                            <p className=" text">{serviceProvider.description}</p>
                                        </div>
                                        }
                                        {!serviceProvider.asylum_types ? <></> : <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.instagram")}</label>
                                            <p className=" text">{serviceProvider.asylum_types}</p>
                                        </div>
                                        }
                                        {!serviceProvider.service_provided ? <></> : <div className="working">
                                            <label className=" label mt-4">{t("auth.register.step_1.twitter")}</label>
                                            <p className=" text">
                                                {(serviceProvider.service_provided || "")}
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default News_details
