
import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { FileUpload } from 'primereact/fileupload';
import Upload from '../../../../components/Upload';

function News_control({ isNew }) {

    const { t } = useTranslation();
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({
        name: "",
        nickname: "",
        email: "",
        phone: "",
        website: "",
        facebook: "",
        logo: "/img/example.png",
        address: "",
        location: "",
        description: "",
        working_hours: "",
        asylum_types:"",
        service_provided: "",
        services: [],
        focal_points: []  // Default selected service (e.g., "1")

    });
    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };
    const DisplayText = ({ text }) => {
        const formattedText = text.replace(/\t/g, "    "); // Replace \t with 4 spaces
        return <div style={{ whiteSpace: "pre-wrap" }}>{formattedText}</div>;
      };
    const [edit, setEdit] = React.useState({
        status: false,
        id: ''
    });
    const toast = React.useRef(null);
    // language
    const Lang = localStorage.getItem("i18nextLng");
    // loading state
    const [isLoading, setIsLoading] = useState(true);
    // langs options
    const [langsList, setLangsList] = useState([{ id: "en", name: "English", }, { id: "ar", name: "العربية", }
    ]);
    const services = [{ id: 1, name: t("Livel") }, { id: 2, name: t("Health") }, { id: 3, name: t("Education") }, { id: 4, name: t("Legal") }, { id: 5, name: t("GBV") },  { id: 7, name: t("Housing") }, { id: 8, name: t("CP") }, { id: 9, name: t("ca_ss") }, { id: 10, name: t("MHPSS") },
    ];
    useEffect(() => {
        if (!isNew) {
            axiosInstance()
                .get(`/service-provider/${id}`)
                .then((response) => {
                    if (!response.data.data.logo) {
                        response.data.data.logo = "/img/example.png"
                    }
                    setInitialValues(response.data.data);
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    //  toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });
        } else {
            setIsLoading(false);
        }

    }, [Lang]);
    const [contentLang, setContentLang] = React.useState("en");
    function handleChange(e, name) {
        setContentLang(e.target.value)
    }

    function handleSubmit(values) {
        setIsLoading(true)
        let newVals = { ...values };
        for (const [key, val] of Object.entries(newVals)) {
            if (key == "focal_points" && val == []) {
                delete newVals[key];
            }
            if (key == "logo" && typeof val == "string") {
                delete newVals[key];
            }
        }

        let formData = new FormData();
        for (const [key, val] of Object.entries(newVals)) {
            if (key === "services") {
                val.forEach((service, index) => {
                    formData.append(`services[${index}]`, service);
                });
            } else if (key === "focal_points") {
                val.forEach((fp, index) => {
                    Object.entries(fp).forEach(([fpKey, fpVal]) => {
                        formData.append(`focal_points[${index}][${fpKey}]`, fpVal);
                        formData.delete(`focal_points[${index}][${'id'}]`)
                    });
                });
            } else if (key === "local_government_registry") {
                // Convert "yes" to "no" (or vice versa) as per logic
                formData.append(key, val === "yes" ? "no" : val);
            } else if (key === "logo") {
                // If `values.logo` is a File object, append it
                if (val instanceof File) {
                    formData.append("logo", val);
                }
            } else {
                formData.append(key, val);
            }
        }
        !isNew
            ?
            axiosInstance()
                .post(`/service-providers/${id}`, formData)
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/news/${id}`
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                })
            :
            axiosInstance()
                .post(`service-providers`, formData, { 'headers': { 'locale': contentLang } })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setIsLoading(false);
                        toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                        setTimeout(() => {
                            window.location.href = `/admin/news`;
                        }, 1500);
                    } else {
                        setIsLoading(false);
                        throw new Error("Unexpected response status.");
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({
                        severity: 'error',
                        summary: t("error"),
                        detail: err.response?.data?.message || "An unexpected error occurred.",
                        life: 3000
                    });
                });
    };
    function FormBody({ handleSubmit, values }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row justify-content-between no-gutters mb-5">

                    <h3 className="alreadyReg d-flex align-items-center">{isNew ? t("addN") : t("editN")}</h3>
                    <div className="buttons align-items-center d-flex">
                        {
                            isNew
                                ?
                                <div className="d-inline-block mx-4">
                                    <select
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        onChange={(e) =>
                                            handleChange(e, "language", "language")
                                        }
                                    >
                                        {langsList.map((type) => (
                                            <option value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                ""
                        }
                        <button type="submit" className="submit-button my-auto text-capitalize d-inline-block">{t("submit")}</button>
                        {
                            !isNew
                                ?
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/news/${id}`}>{t("cancel")}</NavLink></button>
                                :
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/news`}>{t("cancel")}</NavLink></button>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-3 row" style={{ "alignContent": "baseline" }}>

                        <div className="col-12 p-2 id-photo" style={{ paddingInlineEnd: "20px" }}>

                            {/* FEATURED IMAGE */}
                            <img alt="logo" className="img-fluid mt-3 w-100 serv_img" style={{ "maxHeight": "300px" }} src={`${values.logo && typeof values.logo !== "string" ? URL.createObjectURL(values.logo) : values.logo === null ? "/img/example.png" : values.logo}`} />

                            <Field onClick={btnClicked} className="field" name="logo">
                                {({ form }) => (
                                    <>
                                        <FileUpload
                                            onSelect={(event) => {
                                                let file = event.files[0];
                                                let reader = new FileReader();
                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("logo", file);
                                                }
                                            }}
                                            ref={inputFile}
                                            accept="image/*"
                                            autocomplete={false}
                                        />
                                        <input
                                            type="file"
                                            className="d-none"
                                            ref={inputFile}
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                let reader = new FileReader();

                                                if (file && file.type.match('image.*')) {
                                                    let url = reader.readAsDataURL(file);
                                                    setFileName(file.name);
                                                    form.setFieldValue("logo", file);
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </Field>

                            <Field
                                onClick={btnClicked}
                                name="logo"
                                placeholder={fileName || t("upImg")}
                                value={fileName || ""}
                                icon="fas fa-file-upload"
                                className="w-100 input-style cursor-none upload mb-3"
                            />
                        </div>

                    </div>

                    <div className="col-9">
                        <div className="row">
                            <div className="col-sm-4 mb-md-0 mb-3">
                                <label>{t("auth.register.step_1.fullName")}</label>
                                <Field
                                    required
                                    className="w-100 input-style"
                                    name="name"
                                    placeholder={t("auth.register.step_1.fullName")}
                                />
                                <div className="col-12 mb-4">
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mb-md-0 mb-3 px-sm-3">
                                <label>{t("auth.register.step_1.shortName")}</label>
                                <Field
                                    required
                                    className="w-100 input-style"
                                    name="nickname"
                                    placeholder={t("auth.register.step_1.shortName")}
                                />
                                <div className="col-12 mb-4">
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="nickname" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 mb-md-0 mb-3">
                                <label>{t("services")}</label>
                                <Field name="services">

                                    {({ field, form }) => (
                                        <div>
                                            <select
                                            required
                                                onChange={(e) => {
                                                    const selectedId = parseInt(e.target.value, 10); // Convert to number
                                                    form.setFieldValue("services", [selectedId]); // Replace with the last selected service
                                                }}
                                                className="w-100 input-style"
                                            >
                                                <option value="">{t("auth.register.step_1.gender")}</option>
                                                {services.map((service) => (
                                                    <option key={service.id} value={service.id}>
                                                        {service.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.emailAddress")}</label>
                                <Field
                                
                                    rows={9}
                                    className="w-100 input-style"
                                    type="email"
                                    name="email"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.emailAddress")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.emailAddressOp")}</label>
                                <Field
                                
                                    rows={9}
                                    className="w-100 input-style"
                                    name="address"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.emailAddressOp")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="address" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.phone")}</label>
                                <Field
                                    
                                    rows={9}
                                    className="w-100 input-style"
                                    name="phone"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.phone")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="phone" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.shortName_place")}</label>
                                <Field
                                rows={9}
                                    className="w-100 input-style"
                                    name="location"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.shortName_place")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="location link" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.website")}</label>
                                <Field
                                rows={9}
                                    className="w-100 input-style"
                                    name="website"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.website")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="website link" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_1.facebook")}</label>
                                <Field
                                rows={9}
                                    className="w-100 input-style"
                                    name="facebook"
                                     as="textarea"
                                    placeholder={t("auth.register.step_1.facebook")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="website link " />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("auth.register.step_3.col_1.workingHours")}</label>
                                <Field
                                rows={9}
                                    className="w-100 input-style"
                                    name="working_hours"
                                     as="textarea"
                                    placeholder={t("auth.register.step_3.col_1.workingHours")}
                                />
                            </div>
                            <div className="col-12">
                                <label>
                                    {t("auth.register.step_1.twitter")}
                                </label>
                                <Field
                                    rows={9}
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="service_provided"
                                    placeholder={t("auth.register.step_1.twitter")}
                                />
                            </div>
                            <div className="col-12">
                                <label>
                                    {t("auth.register.step_1.linkedin")}
                                </label>
                                <Field
                                    rows={9}
                                   
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="description"
                                    placeholder={t("auth.register.step_1.linkedin")}
                                />
                                
                            </div>
                            <div className="col-12">
                                <label>
                                    {t("auth.register.step_1.instagram")}
                                </label>
                                <Field
                                rows={9}
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="asylum_types"
                                    placeholder={t("auth.register.step_1.instagram")}
                                />
                                
                            </div>
                            <div className='col-sm-4 mb-md-0'>
                                <label className='title'>{t("auth.register.step_4.title")}</label>
                                <FieldArray name="focal_points"
                                    render={(arrayHelpers) => (
                                        <div>
                                            {values.focal_points.map((fp, index) => (
                                                <div key={index}> {/* Add key here */}
                                                    <div className=" mb-md-0 mb-3 px-sm-3">
                                                        <label>{t("auth.register.step_1.fullName")}</label>
                                                        <Field
                                                            className="w-100 input-style"
                                                            name={`focal_points[${index}].name`}
                                                            placeholder={t("auth.register.step_1.fullName")}
                                                        />
                                                    </div>
                                                    <div className=" mb-md-0 mb-3 px-sm-3">
                                                        <label>{t("auth.register.step_1.emailAddress")}</label>
                                                        <Field
                                                            className="w-100 input-style"
                                                            type="email"
                                                            name={`focal_points[${index}].email`}
                                                            placeholder={t("auth.register.step_1.emailAddress")}
                                                        />
                                                    </div>
                                                    <div className=" mb-md-0 mb-3 px-sm-3">
                                                        <label>{t("auth.register.step_1.phone")}</label>
                                                        <Field
                                                            className="w-100 input-style"
                                                            name={`focal_points[${index}].phone`}
                                                            placeholder={t("auth.register.step_1.phone")}
                                                        />
                                                    </div>
                                                    <div className=" mb-md-0 mb-3 px-sm-3">
                                                        <label>{t("auth.register.step_4.position")}</label>
                                                        <Field
                                                            className="w-100 input-style"
                                                            name={`focal_points[${index}].position`}
                                                            placeholder={t("auth.register.step_4.position")}
                                                        />
                                                    </div>
                                                    <button type="button" className="reset_button px-4 main-color text-capitalize d-inline-block" onClick={() => arrayHelpers.remove(index)}>
                                                        {t("remove")}
                                                    </button>
                                                </div>
                                            ))}
                                            <button type="button" className="reset_button px-4 main-color text-capitalize d-inline-block" onClick={() => arrayHelpers.push({ name: '', email: '', phone: '', position: '' })}>
                                               {t("auth.register.step_1.age")}
                                            </button>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Form >
        )
    }
    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({
            name: Yup.string().required(t('form:req')),
            nickname: Yup.string().required(t('form:req')),

            //     logo: Yup.mixed().nullable().required(t("form:req")).test("file", t("form:req"), (str) => typeof str === "object"),
            // email: Yup.string().required(t('form:req')),
            // phone: Yup.string()
            //     .matches(/^[0-9]+$/, t("mustNum"))
            //     .min(11, t("minNum"))
            //     .max(11, t("minNum"))
            //     .required(t("req")),
            services: Yup.array()
                .of(Yup.number().required(t("form:req")))
                .length(1, t("form:req")), // Ensure exactly one service is selected
        });
        return schema;
    }
    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="min-H pt-5 SP-Dash px-4">

                < Toast position="top-center" ref={toast} />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={handleValidation}>
                    {FormBody}
                </Formik>
            </div>
        </div>
    )
}
export default News_control
